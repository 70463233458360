const contacts = {
  phones: [
    {
      href: "tel:+79317080052",
      value: "+7 931 708-00-52"
    },
  ],
  address: {
    city: "St. Petersburg",
    street: "Pargolovo, Vyborg highway, 212, building 3A, box 5",
    longitude: '',
    latitude: ''
  },
  workHours: [
    "Mon-Fri: 10:00 - 18:00",
    "Sat-Sun: Day off"
  ]
};

export default contacts;