import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./helpPhone.scss";
import { Messenger } from "./Messenger";

function HelpPhone() {
  return (
    <section id="section-help-phone" className="section-help-phone">
      <Container>
        <Row>
          <div className="wrapper">
            <p>Any other questions? <small>Call us</small></p>
            <Messenger/>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default HelpPhone;
