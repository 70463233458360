const price = {
  car: {
    title: "Passenger car",
    price: 12500
  },
  van: {
    title: "Commercial transport / minibus / minivan",
    price: 15000
  },
  tir: {
    title: "Cargo transport",
    price: 30000
  }
};

export default price;